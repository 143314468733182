import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {RequestOptions, Request, Headers } from '@angular/http';

@Injectable()
export class BeIdService{
    private host = 'http://127.0.0.1:4567/beid';
    //private host = 'http://localhost:9000/v1/test';
    private requestOptions = new RequestOptions({ headers:null, withCredentials: 
        true });
    constructor(
        private http: HttpClient
    ){}

    readBeId(): Observable<any>{        
        return this.http.get(this.host)
            .map(res => res);
    }
}