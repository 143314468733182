import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AssetRepositoryService{
    private host: string;

    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) config: AppConfig
    ){
        this.host = config.baseEndpoint;
    }

    getAssets(endpoint,query): Observable<any>{
        //console.log(this.host + endpoint + "?" + query);
        return this.httpClient.get(this.host + endpoint + "?" + query)
            .map(res => res);
    }

    getAssetsAsync(endpoint,query): Promise<any>{
        //console.log(this.host + endpoint + "?" + query);
        return this.httpClient.get(this.host + endpoint + "?" + query)
            .toPromise();
    }

    addAsset(endpoint,body): Observable<any>{
        return this.httpClient.post(this.host + endpoint,body)
            .map(res => res);
    }

    addAssetAsync(endpoint,body): Promise<any>{
        return this.httpClient.post(this.host + endpoint,body)
            .toPromise();
    }

    deleteAsset(endpoint,uuid): Observable<any>{
        return this.httpClient.delete(this.host + endpoint + "/" + uuid)
            .map(res => res);
    }

    deleteAssetAsync(endpoint,uuid): Promise<any>{
        return this.httpClient.delete(this.host + endpoint + "/" + uuid)
            .toPromise();
    }

    updateAsset(endpoint,uuid,body): Observable<any>{
        return this.httpClient.put(this.host + endpoint + "/" + uuid, body)
            .map(res => res);
    }

    updateAssetAsync(endpoint,uuid,body): Promise<any>{
        return this.httpClient.put(this.host + endpoint + "/" + uuid, body)
            .toPromise();
    }
}